import React, { useLayoutEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { BigNumber } from 'bignumber.js';
import { ToggleButtonGroup } from '../../../ui-kit/ToggleButtonGroup';
import { NumericInput } from '../../../ui-kit/NumericInput';
import { displayRangeValue } from '../../../utils/displayRangeValue';
import { ToggleButton } from '../../primitives/ToggleButton/ToggleButton';
import { useRootStore } from '../../../store/root';
import { EGasFeeCeilingType } from './types';

export interface IAutoRebalanceGasFeeCeilingProps {
  gasFeeCeilingType: EGasFeeCeilingType;
  setGasFeeCeilingType: React.Dispatch<React.SetStateAction<EGasFeeCeilingType>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  marketReferenceCurrencyValue: number;
}

export const AutoExitGasFeeCeiling = ({
  gasFeeCeilingType,
  setGasFeeCeilingType,
  value,
  setValue,
  marketReferenceCurrencyValue,
}: IAutoRebalanceGasFeeCeilingProps) => {
  const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);
  const minUsdValue = currentNetworkConfig.compoundGasCost;
  const minPercentValue = BigNumber(currentNetworkConfig.compoundGasCost)
    .div(BigNumber(marketReferenceCurrencyValue).div(100)).decimalPlaces(2).toNumber();
  const usdValueRef = useRef(minUsdValue.toString());
  const percentValueRef = useRef(minPercentValue.toString());

  const handleOnCeilingTypeSwitch = (newCeilingType: EGasFeeCeilingType) => {
    if (newCeilingType === null) return;
    setGasFeeCeilingType(newCeilingType);
    setValue(
      newCeilingType === EGasFeeCeilingType.usd
        ? usdValueRef.current
        : percentValueRef.current
    );
  }

  useLayoutEffect(() => {
    if (!value) {
      setValue(
        gasFeeCeilingType === EGasFeeCeilingType.usd
          ? usdValueRef.current
          : percentValueRef.current
      );
    } else {
      if (gasFeeCeilingType === EGasFeeCeilingType.usd) {
        usdValueRef.current = value;
      }
      if (gasFeeCeilingType === EGasFeeCeilingType.percent) {
        percentValueRef.current = value;
      }
    }
  }, [value])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="secondary14" color="text.tertiary">
        Gas fee ceiling
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={gasFeeCeilingType}
          onChange={(_event, value) => handleOnCeilingTypeSwitch(value)}
          sx={{ flex: 'unset', width: 'unset' }}
        >
          <ToggleButton
            value={EGasFeeCeilingType.percent}
            size="medium"
            sx={{ width: '43px', height: '36px' }}
          >
            <Typography variant="secondary15" color="text.secondary">%</Typography>
          </ToggleButton>
          <ToggleButton
            value={EGasFeeCeilingType.usd}
            size="medium"
            sx={{ width: '43px', height: '36px' }}
          >
            <Typography variant="secondary15" color="text.secondary">$</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <NumericInput
          value={value}
          onChange={setValue}
          // minValue={
          //   gasFeeCeilingType === EGasFeeCeilingType.usd ? minUsdValue : minPercentValue
          // }
          isPercentage={gasFeeCeilingType === EGasFeeCeilingType.percent}
          endAdornment={gasFeeCeilingType === EGasFeeCeilingType.percent
            ? (
              <Typography variant="secondary15" color="text.tertiary">
                ~{displayRangeValue(marketReferenceCurrencyValue * (Number(value) / 100))}
              </Typography>
            ) : null
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            height: '44px',
            width: '100%',
            borderRadius: '8px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
            background: '#2A2A32',
            py: 2,
            px: 3,
          }}
        />
      </Box>
    </Box>
  );
};
